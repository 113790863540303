

table.list-table {
  border-collapse: collapse;
  width: 100%;
  display: table;

  background: var(--color-element);
  color: var(--color-text);

  tbody tr:nth-child(2n+1){
    background: var(--color-section);
  }
  tbody {
    border-bottom: 1px solid var(--color-element);
  }

  tr:last-child {
    border-bottom: 0;
  }
  th, td {
    text-align: left;
    padding: 15px;
    box-sizing: border-box;
  }
  &.compact{
    th, td {
      padding: 5px;
    }
  }

  th {
    position: sticky;
    top: 0;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1.2rem 1rem 0.8rem;
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
  }

  td {
    border-left: 0;
    border-right: 0;
    white-space: nowrap;
  }
  tbody tr:hover{
    background: var(--color-dark);
    transition: background-color 150ms ease-out;
  }
  &.disabled{
    pointer-events: none;
    cursor: default;
    filter: brightness(0.5);
  }
}