@use "sass:selector";

.form-control{

  &form, form{
    display: flex;
    flex-direction: column;
  }

  .form-group{
    padding: 5px 0;
    &.inline{
      display:flex;
      justify-content: stretch;
      flex-direction: row;
    }
  }

  .ui-button{
    margin-top: 5px;
  }

  .divider{
    width: 1px;
    background-color: var(--color-text);
  }

  .form-section{
    margin: .25rem 0 .1rem 0;
  }


  .form-item {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    border: none;

    &.inline{
      display:flex;
      flex-direction: row;
      gap: .5rem;
      padding: 2px 0;
      >input{
        flex-grow: 0;
        width: auto;
      }
    }

    > input{
      box-sizing: border-box;
      padding: 0.35rem .75rem;
      display: block;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--color-text);
      background: var(--color-element);
      background-clip: padding-box;
      border-radius: 0;

      &:invalid:not(:placeholder-shown):not(:focus){
        outline: var(--color-error) solid 1px;
      }
      &:hover:not(:disabled){
        box-shadow: inset 0 -1px var(--color-highlight2);
      }
      &:has( + .btn-addon){
        //To have proper outline alignment
        padding-right: 2.5rem;
        margin-right: -2.5rem;
      }

      &[hidden]{
        display: none;
        +label{
          cursor: pointer;
          padding: 5px 2px;
          margin: 0;
          display: inline-block;
        }
        &[type="radio"]:checked + label{
          color: var(--color-primary);
        }
      }
    }
    > input + .btn-addon{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 2.5rem;
      min-width: 2.5rem;
      box-shadow: none !important;
      padding: 0;
      .ui-icon{
        margin: 0;
        height: auto;
      }
    }

    > label{
      opacity: 0.7;
    }

  }


  &.form-modal{
    .form-item {
      position: relative;
      --item-height: calc(3.5rem + 2px);
      > input:not([type="submit"]), .btn.btn-addon {
        border: 0px solid var(--color-element);
        border-bottom-width: 3px;
        height: var(--item-height);
      }

      > input:has(~ .btn.btn-addon){
        padding-right: var(--item-height);
      }

      > input::placeholder{
        display:none;
        color: transparent;
      }

      > input:focus, .form-item > input:not(:placeholder-shown){
        &[type="text"], &[type="password"]{
          padding-top: 1.625rem;
          padding-bottom: .625rem;
        }
      }

      > input:focus:not(:disabled){
        color: var(--color-text);
        background: none;
        outline: 0;
      }

      > label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: hidden;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        border: 1px solid transparent;
        transform-origin: 0 0;
        transition: opacity .1s ease-in-out,transform .1s ease-in-out;
      }
      input:focus ~ label, input:not(:placeholder-shown) ~label{
        opacity: .65;
        transform: scale(.85) translateY(-.5rem) translateX(.15rem);
      }

      >.btn.btn-addon{
        position: absolute;
        top:0;
        right:0;
        width: var(--item-height);
        height: var(--item-height);
        min-width: auto;
        &[aria-checked="true"]{
          color: var(--color-secondary);
        }
        &:hover{
          color: var(--color-secondary-light);
        }
      }
    }

    &[disabled] .form-item > input, .form-item > input:disabled {
      background: var(--color-element);
      pointer-events: none;
    }

    input:focus ~ label, input:not(:placeholder-shown) ~label{
      opacity: .65;
    }

  }
  
  input[type="submit"] {
    background: var(--color-highlight2);
    color: white;
    cursor: pointer;
    &:hover:not(:disabled){
      background: var(--color-highlight);
      box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    }
  }

  input[type=file]{
    &.dropzone{
      max-width: 100%;
      flex: 1 1 auto;
      background: var(--color-highlight2);
    }
    &::file-selector-button {
      margin-right: 8px;
      border: none;
      background: var(--color-secondary);
      padding: 8px 12px;
      color: #fff;
      cursor: pointer;
    }
  }

  .form-checkbox{
    display: flex;
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }    
  }

  .form-item > select{
    border: 0 !important; 
    width: 100%;
    padding: 5px;
    background: none;
    border: none;
    color: inherit;
    background: var(--color-section);
    cursor: pointer;
    option{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    @at-root .list-table &{
      background: none;
      &:hover{
        box-shadow: 0px 2px 1px var(--color-section);
      }
    }
  }
  
  &:disabled *, & *:disabled, & *:disabled * {
    pointer-events: none;
    opacity: 0.7;
  }
}
