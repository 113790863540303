
.text-main{
  color: var(--color-text);
}

.text-dark{
  color: var(--color-dark);
}

.text-info {
  color: var(--color-info);
}
.text-success {
  color: var(--color-success); 
}
.text-warning{
  color: var(--color-warning); 
}
.text-error {
  color: var(--color-error); 
}

.text-primary{
  color: var(--color-primary);
}

.text-muted{
  color: #666;
}

@mixin text-colors{
  &.text-info, &.text-success, &.text-warning, &.text-error, &.text-primary{
    @content;
  }
}