
@mixin flush-column{
  .flush{
    margin: 0 var(--flush-margin);
    &:first-child{
      margin-top: var(--flush-margin);
    }
    &:last-child{
      margin-bottom: var(--flush-margin);
    }
  }
}

@mixin flush-row{
  .flush{
    margin: var(--flush-margin) 0;
    &:first-child{
      margin-left: var(--flush-margin);
    }
    &:last-child{
      margin-right: var(--flush-margin);
    }
  }
}


section, .section{
  --section-padding: 1rem;
  --caret-width: 2px;
  position: relative;
  background: var(--color-section);

  &.container{
    max-width: 1200px;
    margin: 1rem auto;
  }

  &::before, &::after{
    content: "";
    position: absolute;
    height: 41px;
    width: 41px;

    pointer-events: none;
    border-color: var(--color-primary);
    border-style: solid;
  }
  &::before{
    top: 0;
    left: 0;
    border-width: var(--caret-width) 0 0 var(--caret-width);
  }
  &::after{
    bottom: 0;
    right: 0;
    border-width: 0 var(--caret-width) var(--caret-width) 0;
  }


  padding: var(--section-padding);
  margin-bottom: var(--section-padding);



  .flush {
    --flush-margin: calc(-1 * var(--section-padding));

    display:block;
    height: auto;
    width: calc( 100% + 2rem);
    
    img{
      display:block;
      height: auto;
      width: 100%;
    }
  }
  @include flush-row();

  h1, h2, h3, h4, h5, h6{
    &:first-child{
      margin-top: 0;
    }
  }
  
  
}

.section-column{
  display: flex;
  gap: var(--section-padding);
  //Row or column layout is set depending on which part of the grid the columns are in
  >.col{
    flex: 1 1 50%;
    min-width: 0;
  }
}




.main-grid{
  display: grid;
  gap: .5rem;
  grid-auto-rows: auto;
  grid-template-columns: minmax(auto, 300px) 1fr;
  grid-template-areas: 
    '. header'
    'toolbar content';
  
  @media screen and (max-width: 992px){
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 
    '. toolbar .'
    '. header .'
    '. content .';
  }

  .grid-header, .grid-toolbar, .grid-content{
    min-width: 0;
  }

  .grid-header{
    grid-area: header;
  }

  .grid-toolbar{
    grid-area: toolbar;
    section, .section{
      display: flex;
      flex-direction: column;
      @include flush-column();
    }

    .section-column{
      @media screen and (max-width: 992px) and (min-width: 576px){
        flex-direction: row;
        @include flush-row();
      }
    }
  }

  .grid-content{
    grid-area: content;

    .section-column{
      @media screen and (max-width: 992px){
        flex-direction: column;
        @include flush-column();
      }
    }
  }
}

.list-tasks{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  .ui-button{
    flex: 0 0 auto;
  }
}

.list-grid{
  display:grid;
  gap: 5px;
  grid-template-columns: auto;

  @media (min-width: 610px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1600px){
    grid-template-columns: repeat(4, 1fr);
  }
}

.list-items{
  
  .list-item, .list-header{
      display: flex; 
      flex: 1 1 auto;
      gap: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      background: var(--color-element);
      padding: 1rem;
      border-color: transparent;
      border-width: 1px 0 1px 0;
  }

  .list-header{
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1.2rem 1rem 0.8rem 1rem;
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
  }

  .list-item{
    &:nth-child(2n){
      background: var(--color-section);
    }
    &:hover{
      background: var(--color-dark);
      filter: opacity(80%);
      border-color: var(--color-dark);
    }
  }
}


footer{
  color: var(--color-text);
  background-color: var(--color-section);
  height: var(--nav-height);
  padding-right: 105px;
  padding-left: 105px;
  position: relative;

  nav{
    height: 100%;
    display: flex;
    justify-content: center;
  }
  #change-locale{
    position: absolute;
    right: 0;
    top: 0;
    height: var(--nav-height);
  }

  @media (max-width: 576px){
    padding-left: 1rem;
    height: auto;
    nav{
      flex-direction: column;
      align-items: flex-start;
    }
  }
}


.tabs{
  .tab{
    display: none;
    width: 100%;
    
    &:first-child:not(:has(~ .tab:target)),
    &:target {
      display: block;
    }
  }
}

.tags-list{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .btn.btn-small{
    min-width:60px;
    height: 24px;
    border-radius: 4px;
    flex-shrink: 0;
  }
  .add-tag{
    border-radius: 4px;
    overflow: hidden;
    > input[type="text"]{
      padding-top: 0;
      padding-bottom: 0;
    }
    cursor: text;
    >.btn-addon{
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 992px){
  .lg-hidden{
    display: none !important;
  }
}

@media screen and (max-width: 992px){
  .lg-only{
    display: none !important;
  }
}

@media screen and (min-width: 576px){
  .sm-only{
    display: none !important;
  }
}
@media screen and (max-width: 576px){
  .sm-hidden{
    display: none !important;
  }
}