


button, input {
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  cursor: inherit;
}


a{
  color:white;
}


.btn {
  background-color: var(--color-highlight);
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  flex-wrap: wrap;
  padding: 8px;
  min-width: 100px;

  transition: fill 0.2s;

  cursor: pointer;


  &:hover, &:focus{
    background-color: var(--color-highlight2);
  }

  &[selected] {
    background-color: var(--color-primary);

    &:hover, &:focus {
      background-color: var(--color-primary-light);
    }
  }

  &.btn-main {
    background-color: var(--color-secondary);

    &:hover:not([disabled]){
      background-color: var(--color-secondary-light);
      box-shadow: 2px 2px rgba(20, 20, 20, 0.3);
    }
  }

  &.btn-primary {
    background-color: var(--color-primary);

    &:hover:not([disabled]){
      background-color: var(--color-primary-light);
      box-shadow: 2px 2px rgba(20, 20, 20, 0.3);
    }
  }

  &.btn-secondary {
    background-color: var(--color-secondary);

    &:hover:not([disabled]){
      background-color: var(--color-secondary);
    }
  }

  &.btn-danger{
    background-color: var(--color-error);
    &:hover:not([disabled]){
      color: #333;
      filter: brightness(1.2);
      box-shadow: 2px 2px rgba(20, 20, 20, 0.3);
    }
  }

  &.btn-small{
    padding: 0 4px;
    min-width: auto;
  }

  &.btn-inline{
    display: inline-block;
  }
  
  &.btn-outline{
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    &:hover:not([disabled]){
      background-color: var(--color-primary);
      color: white;
      &:btn-secondary{
        background-color: var(--color-secondary);
      }
    }
  }


  &.btn-danger {
    background-color: var(--color-error);
    color: white;

    &:hover{
      filter: brightness(110%);
    }    
  }

  &.btn-transparent{
    background-color: transparent !important;
    &.btn-primary{
      color: var(--color-primary);
      &:hover:not([disabled]){
        text-decoration: underline;
        color: var(--color-primary-light);
        box-shadow: none;
      }
    }
  }

  /**
   * Special case of button used as a popover source
   * Combines btn-transparent and btn-inline, with minor adjustments
   */
  &.btn-popover{
    background-color: transparent !important;
    display: inline-block;
    line-height: 12px;
    vertical-align: top;
    height: 1lh;
    position: relative;
    color: var(--color-highlight2);
    min-width: auto;
    cursor: help;
    padding: 0;
    > .ui-icon{
      display: inline-block;
      margin: 0;
      height: 1lh;
    }
    &:hover{
      color: var(--color-primary);
    }
    //The popover element
    >[popover]:popover-open{
      line-height: initial;
      inset: unset;
      margin: 0 1rem;
      padding: .2rem .4rem;
      border: 1px solid var(--color-primary);
      border-radius: 4px;
      color: var(--color-text);
      background-color: var(--color-highlight2);

      font-size: 1rem;
      font-family: var(--font-body);
    }
  }

  @at-root a#{&} {
    text-decoration: none;
    white-space:nowrap;
  }

  & > .btn-text {
    margin: 3px;
    white-space: nowrap;
  }

  & > .ui-icon {
    margin: 3px;
    height: 1.3em;
    fill: currentColor;
    svg{
      height: 1rem;
      height: 1lh;
    }
  }

  & > .btn-caret-down {
    margin: 3px;
  }

  &[disabled]{
    user-select: none;
    pointer-events: none;
  }
  &[transparent]{
    background: transparent;
    color: var(--color-text);
    &:hover{
      color: var(--color-highlight2);
    }
  }
}

.caret{
  &::before{
    content: "⌄";
    display: inline;
    line-height: 75%;
    vertical-align: top;
  }

  .visible &::before,
  .active &::before, 
  &.active::before
  {
    content: "⌃";
    vertical-align: bottom;
  }
}