
.landing-page {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 88px - 2rem);
  flex-wrap: wrap;
  .illustration{
    width:67%;
    min-width:300px;
    max-width: calc(100vh - 88px - 2rem);
    flex: 1 1 auto;
  }
  .user-login {
    border: 1px solid var(--color-element);
    box-shadow: 0 0 5px 0 #00000088;
    border-radius: 5px;
    width: 33%;
    padding: 1rem;
    min-width:300px;
    max-width: 400px;
    flex: 1 1 auto;
  }
  
  img{
    display: block;
    max-width: 100%;
    height: auto;
  }
}