


nav.navbar-nav {
  position: fixed;
  top: 0;
  width:100%;
  height: var(--nav-height);
  box-sizing: border-box;
  display: flex;
  justify-content:center;
  z-index: 1001;

  background-color: var(--color-section);
  border-bottom: 3px solid var(--color-element);

  .nav-content{
    flex: 1 1 auto;
    max-width: var(--col-width);
    height: var(--nav-height);
    display:flex;
    justify-content: space-between;
  }

  .spacer{
    flex: 1 1 auto;
  }

  .divider{
    width: 1px;
    background-color: var(--color-dark);
  }


  .brand {
    font-size: 1.1em;
    color: var(--color-secondary);
    font-family: var(--font-heading);
    font-weight: 400;
    text-decoration: none;
    img{
      height: 100%;
      width: auto;
      display: block;
    
    }
  }
  .brand:hover {
    filter: opacity(80%);
  }
  
}

nav{

  .navbar{
    display: flex;
    align-items: stretch;
    flex-basis: auto;
    justify-content: end;
    align-items: center;
    .btn, .nav-link, .form-item{
      height:100%;
      flex: 0 0 auto;
      box-sizing: border-box;
      background-color: transparent;
      font-size: 13px;
      color: var(--color-text);
    }
    .btn{
      padding: 4px 10px !important;
    }
  }
  .nav-link{
    position: relative;
    flex-wrap: wrap;
    height:100%;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    box-sizing: border-box;
    @media screen and (min-width: 576px){
      padding: 4px 10px;
    }
    
    margin: 3px;
    border-bottom: 3px solid transparent;
    font-size: 13px;
    transition: border 0.2s ease 0s;

    color: inherit;


    svg { fill: currentColor; }
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;

    &:hover, &:focus{
      color: white;
      background-color: var(--color-highlight2);
    }
  
    &.active {
      border-bottom: 3px solid var(--color-primary);
      &:hover, &:focus {
        border-bottom: 3px solid var(--color-primary-lighter);
      }
    }
  }
  .form-control{
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  .form-item.nav-input{
    flex: 1 1 auto;
    color: white;
    min-width: var(--nav-height);
    >input{
      width:100%;
      background: none;
      background: var(--color-highlight);
      border: 1px solid var(--color-highlight2);
      border-radius: 4px;
      padding-bottom: 0;
      padding-top: 0;
    }
  }


  .nav-end{
    justify-self: flex-end;
  }

}
