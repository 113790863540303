
@import "./normalize.scss";
@import "./layout.scss";
@import "./tables.scss";
@import "./buttons.scss";
@import "./forms.scss";

@import "./text.scss";
@import "./titles.scss";

.tooltip{
  div{
    display: none;
  }
  &.visible{
    div{
      display: block;
    }
  }
}
