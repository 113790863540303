
.scene-card {
  position: relative;
  --card-height: 70px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: stretch;
  align-items: stretch;
  background-color: var(--color-element);
  border: 2px solid var(--color-highlight);
  transition: background-color 0.2s;
  border-radius: 4px;
  overflow: hidden;

  &:hover{
    background-color: var(--color-highlight);
    border-color: var(--color-highlight2);
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  }

  .card-image{
    flex: 0 0 auto;
    >img{
      display: block;
      aspect-ratio: 1/1;
      height: var(--card-height);
      width: var(--card-height);
      object-fit: cover;
      border-radius: 4px;
      border: 2px solid var(--color-highlight);
    }
  }

  .scene-card-inner{
    padding: .5rem;
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    @at-root .list-grid & {
      flex-direction: column;
    }
  }


  .infos{
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    align-self: stretch;
  }

  .tools{
    max-width: 100%;
    margin-top: 0.5rem;
    display:flex;
    justify-content: flex-end;
    overflow: hidden;
    align-self: center;
    a{
      margin: 2px;
      color: #eee;
      text-decoration: none;
      padding: 0 0.5rem;
      min-width: 24px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &:hover{
        color: var(--color-secondary-light);
      }
    }
  }

  .card-header{
    display: flex;
    align-self: stretch;
    width: 100%;
  }

  .card-title{
    flex: 1 1 auto;
    min-width: 0;
    margin:0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .card-time{
    color: #6c757d;
    font-size: smaller;
  }

  .tools svg{
    width: inherit;
    height: 1rem;
    fill: currentColor;
    margin-right: 4px;
  }

  .pill{
    padding: 6px;
  }
  .pill input{
    width: 20px;
    height: 20px;
  }


  // small-ish screens, but not _that_ small
  @media screen and (max-width: 992px){
    .scene-card-inner{
      flex-direction: column;
    }
  }
  //Vertical mobile screens
  @media screen and (max-width: 576px){
    .scene-card-inner{
      padding: .5rem .1rem;
    }
    .infos{
      flex-direction: column;
    }
    .tools{
      justify-content: flex-end;
    }
  }
}
